import {CSSProperties} from 'react';
import {ShouldRenderVarsFnParams, CustomCssVarsFnParams} from '../../../baseCustomCssVars';
import {
  Alignments,
  ButtonWidthType,
  ImagePlacements,
  ImagePositions,
  RibbonPlacementId,
  RibbonType,
  VerticalAlignments,
} from '../../../types/galleryTypes';

/* istanbul ignore file */
// eslint-disable-next-line sonarjs/cognitive-complexity
export const productItemCustomCssVars = ({styleParams: styles, isMobile, isRTL}: CustomCssVarsFnParams) => {
  const isHorizontalLayout =
    styles.strings[isMobile ? 'mobile:gallery_imagePlacement' : 'gallery_imagePlacement'].value ===
    ImagePlacements.HORIZONTAL;
  const productItemVerticalAlignment = styles.strings.gallery_verticalAlignment.value;

  const getProductItemFlexDirection = (isOddIndex): CSSProperties['flexDirection'] => {
    const imagePosition = styles.strings[isMobile ? 'mobile:gallery_imagePosition' : 'gallery_imagePosition'].value;
    const shouldAlternateImagePosition =
      styles.booleans[isMobile ? 'mobile:gallery_alternateImagePosition' : 'gallery_alternateImagePosition'];
    const row = 'row';
    const rowReverse = 'row-reverse';

    if (!isHorizontalLayout) {
      return 'column';
    }

    if (isOddIndex && shouldAlternateImagePosition) {
      if (isRTL) {
        return imagePosition === ImagePositions.LEFT ? row : rowReverse;
      }

      return imagePosition === ImagePositions.RIGHT ? row : rowReverse;
    }

    if (isRTL) {
      return imagePosition === ImagePositions.RIGHT ? row : rowReverse;
    }

    return imagePosition === ImagePositions.LEFT ? row : rowReverse;
  };

  const getProductItemAlignItems = (): CSSProperties['alignItems'] => {
    const alignment = styles.strings.gallery_alignment.value;
    const flexStart = 'flex-start';
    const flexEnd = 'flex-end';

    switch (alignment) {
      case Alignments.LEFT:
        return isRTL ? flexEnd : flexStart;
      case Alignments.RIGHT:
        return isRTL ? flexStart : flexEnd;
      case Alignments.CENTER:
      default:
        return 'center';
    }
  };

  const getProductItemJustifyContent = (): CSSProperties['justifyContent'] => {
    if (!isHorizontalLayout) {
      return 'space-between';
    }

    switch (productItemVerticalAlignment) {
      case VerticalAlignments.TOP:
        return 'flex-start';
      case VerticalAlignments.BOTTOM:
        return 'flex-end';
      case VerticalAlignments.STRETCH:
        return 'space-between';
      default:
        return 'center';
    }
  };

  const horizontalLayoutImageWidth = `${
    styles.numbers[isMobile ? 'mobile:gallery_imageWidth' : 'gallery_imageWidth']
  }%`;

  const horizontalLayoutSpacing = `${
    styles.numbers[isMobile ? 'mobile:gallery_imageAndInfoSpacing' : 'gallery_imageAndInfoSpacing']
  }px`;

  const ribbonRectangleType = styles.numbers.gallery_ribbonType === RibbonType.RECTANGLE;
  const showProductDetails =
    styles.booleans.gallery_showProductName ||
    styles.booleans.gallery_showDividers ||
    styles.booleans.gallery_showPrice;
  const showAddToCartButton =
    styles.booleans[isMobile ? 'mobile:gallery_showAddToCartButton' : 'gallery_showAddToCartButton'];
  const verticalNotImageContainerPaddingTop = showProductDetails || showAddToCartButton ? '16px' : '0px';
  return {
    productItemFlexDirection: getProductItemFlexDirection(false),
    productItemOddIndexFlexDirection: getProductItemFlexDirection(true),
    productItemImageWidth: isHorizontalLayout ? horizontalLayoutImageWidth : 'revert',
    productItemNotImageContainerWidth: isHorizontalLayout
      ? `calc(100% - ${horizontalLayoutImageWidth} - ${horizontalLayoutSpacing} + 0px)`
      : 'revert',
    productItemImageAndInfoSpacing: isHorizontalLayout ? horizontalLayoutSpacing : 'revert',
    productItemNotImageContainerPaddingTop: isHorizontalLayout
      ? `${styles.numbers[isMobile ? 'mobile:gallery_productMargin' : 'gallery_productMargin']}px`
      : '16px',
    productItemNotImageContainerPaddingTopFixed: isHorizontalLayout
      ? `${styles.numbers[isMobile ? 'mobile:gallery_productMargin' : 'gallery_productMargin']}px`
      : verticalNotImageContainerPaddingTop,
    productItemVerticalAlignment: getProductItemJustifyContent(),
    productItemHorizontalAlignment: getProductItemAlignItems(),
    productItemDetailsLinkFlexGrow: isHorizontalLayout ? 0 : 1,
    addToCartButtonShowOnHoverOpacity: styles.booleans.gallery_addToCartButtonShowOnHover ? '0' : 'inherit',
    addToCartButtonShowOnHoverOpacityOnHover: styles.booleans.gallery_addToCartButtonShowOnHover ? '1' : 'inherit',
    ribbonPadding: ribbonRectangleType ? '2px 12px' : 'inherit',
    ribbonBackground: ribbonRectangleType ? styles.colors.gallery_ribbonBackground : 'inherit',
    ribbonBorder: ribbonRectangleType
      ? `${styles.numbers.gallery_ribbonBorderWidth}px solid ${styles.colors.gallery_ribbonBorderColor}`
      : 'none',
    addToCartButtonWidth:
      styles.strings.gallery_addToCartButtonWidth.value === ButtonWidthType.FIT ? 'fit-content' : '100%',
  };
};

export const productItemShouldRenderVars = ({booleans, numbers, isMobile}: ShouldRenderVarsFnParams) => {
  const showAddToCartButton = booleans[isMobile ? 'mobile:gallery_showAddToCartButton' : 'gallery_showAddToCartButton'];
  return {
    shouldShowRibbonOnImage:
      booleans.gallery_showRibbon && numbers.gallery_ribbonPlacement === RibbonPlacementId.OnImage,
    shouldShowRibbonOnProductInfo:
      booleans.gallery_showRibbon && numbers.gallery_ribbonPlacement === RibbonPlacementId.ProductInfo,
    showProductDetails: booleans.gallery_showProductName || booleans.gallery_showDividers || booleans.gallery_showPrice,
    notShowProductName: !booleans.gallery_showProductName,
    notShowAddToCartButton: !showAddToCartButton,
    showAddToCartButton,
  };
};
